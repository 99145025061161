import React from "react"
import { graphql } from "gatsby"
import {Layout} from "@components/Layout"
import {Banner,mapToPageHeader} from "@components/Banner"
import {SEO,mapToSEO} from '@components/SEO'
import { SignupBannerWrapper,mapToKiwiVIPVisible} from "@components/SignupBanner";

import {H2, Paragraph1,CustomContainer} from '@styles/Global.styles'
import {GenericBannerContainer} from '@components/GenericBannerContainer'

const BasicInfoPage = (props:any) => {

    const {elements} = props.data.kontentItemBasicPage;
    const seo = mapToSEO(elements);
    const kiwiVip = mapToKiwiVIPVisible(elements);
    const metadata = mapToPageHeader(elements);
    const heading = elements.heading.value;
    const content = elements.content.value;

    return (<Layout location={props.location}>              
        <SEO {...seo} />
        <Banner {...metadata} isBiggerBanner={false} />
        <GenericBannerContainer
          padding={{
            mobile: {
                top: 60,
                bottom: 60
            },
            desktop: {
                top: 80,
                bottom: 80
            }
          }}>
          <CustomContainer width="75%">
            <H2 textAlign="center">{heading}</H2>
            <div id="___gatsby" dangerouslySetInnerHTML={{__html: content}} />
          </CustomContainer>
        </GenericBannerContainer>
        {
            kiwiVip.visible && 
            <SignupBannerWrapper version= {kiwiVip.version} />
        }
  </Layout>);
}

export const query = graphql`
query($slug: String!){
  site {
    siteMetadata {
      apiUrl
    }
  }
  kontentItemBasicPage(fields: { slug: { eq: $slug }}) {
      elements {
        heading {
          value
        }
        content{
          value
        }
        seo__noindex {
          value {
            name
            codename
          }
        }
        seo__nofollow {
          value {
            name
            codename
          }
        }
        seo__page_title {
          value
        }
        seo__page_description {
          value
        }
        general_page_content__title {
          value
        }
        general_page_content__header_image {
          value {
            url
            description
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        general_page_content__kiwivip_signup {
          value {
            name
            codename
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            name
            codename
          }
        }
        general_page_content__title_position{
          value{
            codename
          }
        }
        url {
          value
        }
      }
  }
}
`
export default BasicInfoPage;